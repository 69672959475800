import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={location}>
      <SEO title="Contact" />

      {/*<article className="post-content page-template no-image">*/}
      {/*  <div className="post-content-body">*/}
      {/*    <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">*/}
      {/*      Clean, minimal, and deeply customisable. London is a theme made for*/}
      {/*      people who appreciate simple lines.*/}
      {/*    </h2>*/}
      {/*    <figure className="kg-card kg-image-card kg-width-full">*/}
      {/*      <GatsbyImage*/}
      {/*        image={data.benchAccounting.childImageSharp.gatsbyImageData}*/}
      {/*        className="kg-image" />*/}
      {/*      <figcaption>Large imagery is at the heart of this theme</figcaption>*/}
      {/*    </figure>*/}
      {/*    <h3 id="dynamic-styles">Dynamic styles</h3>*/}
      {/*    <p>*/}
      {/*      London comes with photo-centric main layout best suited to*/}
      {/*      photography, graphics portfolios and other image-heavy uses.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Both post and page templates are light and minimal, with all the*/}
      {/*      focus on the content while the design of the theme gets out of the*/}
      {/*      way. Beneath the hood, London enjoys the full power of the{" "}*/}
      {/*      <a href="https://docs.ghost.org/api/handlebars-themes/">*/}
      {/*        Ghost Handlebars Theme API*/}
      {/*      </a>{" "}*/}
      {/*      to provide limitless customisation options and dynamic styles.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      Don't forget to check out the{" "}*/}
      {/*      <a href="https://docs.ghost.org/integrations/">*/}
      {/*        Ghost Integrations Directory*/}
      {/*      </a>{" "}*/}
      {/*      for more ways to integrate Ghost with your favourite services.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</article>*/}
    </Layout>
  );
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  benchAccounting: file(relativePath: {eq: "bench-accounting-49909-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const Contact = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage location={props.location} data={data} {...props} />
    )}
  />
)
export default Contact
